import axios from "axios";

export default new class {

    getAPIPath(apiBaseUrl) {
        if (apiBaseUrl) {
            return apiBaseUrl;
        } else if (process.env['NODE_ENV'] === 'development') {
            return 'http://localhost:7266/api';
        }
        return '%%API_BASE_URL%%/api';
    }

    async generatePDFsWithInput(object) {
        return await axios.post(`${this.getAPIPath(process.env.VUE_APP_ROOT_API)}/SignPrintingFunction_HttpStart`, object).then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    }
    async generatePDFsWithExcelFile(object) {
        return await axios.postForm(`${this.getAPIPath(process.env.VUE_APP_ROOT_API)}/SignPrintingFunction_HttpStart`, object).then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    }

    async getProgress(URL) {
        return await axios.get(URL).then(response => {
            return { data: response.data, customStatus: JSON.parse(response.data.customStatus) };
        }).catch(error => {
            throw error;
        });
    }
}