<template>
    <b-row class="justify-content-center">
        <b-col cols="6" md="3" lg="2" class="text-center">
            <b-button class="h-100" v-on:click="selectedOption('list')">Indtast varenumre</b-button>
        </b-col>
        <b-col cols="6" md="3" lg="2" class="text-center" v-if="showExcelOption">
            <b-button v-on:click="selectedOption('excel')" class="h-100">Indlæs excel ark</b-button>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "SelectTypeOfImport",
    props: ["showExcelOption"],
    methods: {
        selectedOption(type) {
            this.$emit('selectedOption', type);
        }
    }
}
</script>