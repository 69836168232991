<template>
    <b-row>
        <b-col cols="12" class="text-center">
            <h4 class="mb-3">Resultat</h4>
        </b-col>
        <b-col cols="12" lg="6" class="text-center mt-3 mb-2 mb-lg-0">
            <h5 class="mb-2">
                Sammenlagte PDF'er
            </h5>
            <ul class="result-ul">
                <li :key="index" v-for="(item, index) in getMergedFilesWithURL">
                    <a :href="item.uri" target="_blank">
                        {{ item.name }}
                    </a>
                </li>
            </ul>
        </b-col>
        <b-col cols="12" lg="6" class="text-center mt-3 mb-2 mb-lg-0">
            <h5 class="mb-2">
                Individuelle PDF'er
            </h5>
            <ul class="result-ul">
                <li :key="index" v-for="(item, index) in getIndividualFilesWithURL">
                    <a :href="item.uri" target="_blank">
                        {{ item.name }}
                    </a>
                </li>
            </ul>
        </b-col>
        <b-col cols="6" class="mt-4 mx-auto">
            <b-alert variant="danger" class="mt-5" show v-if="errorMessage" v-html="errorMessage">
            </b-alert>
        </b-col>
        <b-col cols="12" class="mt-4 text-center">
            <b-button variant="outline-primary" @click="restart">
                Genstart
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import pdfService from '@/services/pdfService';

export default {
    name: "ResultLinks",
    props: ['result'],
    computed: {
        getMergedFilesWithURL() {
            return this.$props.result.output.MergedPdfs.map(m => {

                var uriParts = m.split("/")
                return {
                    uri: `${pdfService.getAPIPath(process.env.VUE_APP_ROOT_API)}/DownloadPdf/${encodeURIComponent(uriParts[0])}/${encodeURIComponent(uriParts[1])}`,
                    name: m.substring(m.lastIndexOf("/") + 1, m.length)
                }
            });
        },
        getIndividualFilesWithURL() {
            return this.$props.result.output.SinglePdfs.map(m => {
                m = m.replaceAll("\"", "");

                var uriParts = m.split("/")

                return {
                    uri: `${pdfService.getAPIPath(process.env.VUE_APP_ROOT_API)}/DownloadPdf/${encodeURIComponent(uriParts[0])}/${encodeURIComponent(uriParts[1])}`,
                    name: m.substring(m.lastIndexOf("/") + 1, m.length)
                }
            });
        },
        errorMessage() {
            return this.$props.result.output.ErrorMessage;
        }
    },
    methods: {
        restart() {
            this.$emit("restart");
        }
    }
}
</script>