<template>
    <b-row class="justify-content-center">
        <b-col cols="12" md="4" class="text-center">
            <b-form @submit="onSubmit">
                <h4 class="mb-3">Vælg excel fil:</h4>
                <b-form-file required class="text-left mb-4" v-model="file" :state="Boolean(file)"
                    placeholder="Browse file..." drop-placeholder="Drop file here..." size="sm" accept=".xlsx">
                </b-form-file>

                <b-form-group class="mb-4 text-left" label="Email:">
                    <b-form-input v-model="email" placeholder="example@email.com"></b-form-input>
                </b-form-group>

                <b-button type="reset" @click="goBack" class="mr-2" variant="outline-primary">Gå tilbage</b-button>
                <b-button type="submit">
                    Print
                </b-button>
            </b-form>
        </b-col>
    </b-row>
</template>

<script>
import pdfService from "@/services/pdfService";

export default {
    name: "ExcelImport",
    data() {
        return {
            file: null,
            email: ""
        }
    },
    methods: {
        async onSubmit($event) {
            $event.preventDefault();
            let formData = new FormData();

            formData.append("file", this.file);
            if (this.email) {
                formData.append("email", this.email);
            }

            let response = await pdfService.generatePDFsWithExcelFile(formData);
            this.$emit("goToProgress", { statusUri: response.statusQueryGetUri, milliseconds: 30000, showEmailStep: this.email ? true : false });
        },
        goBack() {
            this.$emit("reset");
        }
    }
}
</script>