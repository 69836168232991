<template>
    <b-row class="mt-5 pt-4 justify-content-center">
        <b-col cols="10">
            <h4 class="text-center mb-5">Status</h4>

            <b-row>
                <b-col :key="index" v-for="(item, index) in checkListFiltered">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <b-spinner v-if="index == step"></b-spinner>
                        <b-icon-circle color="#0c3153" font-scale="2" v-if="!item.completed && step < index" />
                        <b-icon-circle-fill color="#0c3153" font-scale="2" v-if="item.completed" />
                        <p class="mb-0 text-center mt-3">{{ item.name }}</p>
                        <b-icon-arrow-right v-if="index < checkListFiltered.length - 1" font-scale="2"
                            class="icon-arrow" />
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-5 pt-4 justify-content-center">
                <b-col cols="12">
                    <p class="mb-0 text-center mt-3">Vent venligst. Du kan holde vinduet åbent imens skiltene printes. Hvis du har udfyldt email adresse vil du også få pdf'erne tilsendt.</p>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</template>

<script>
import pdfService from '@/services/pdfService';

export default {
    name: "ProgressReport",
    props: ["URL", "intervalForStatus", "showEmailStep"],
    data() {
        return {
            interval: null,
            step: 0,
            checkList: [
                {
                    name: "Henter produkt data",
                    completed: false
                },
                {
                    name: "Henter priser",
                    completed: false
                },
                {
                    name: "Bygger skilte",
                    completed: false
                },
                {
                    name: "Bygger QR koder og stregkoder",
                    completed: false
                },
                {
                    name: "Genererer pdf'er",
                    completed: false
                },
                {
                    name: "Gemmer pdf'er",
                    completed: false
                },
                {
                    name: "Samler pdf'er",
                    completed: false
                },
                {
                    name: "Sender email",
                    completed: false
                }
            ]
        }
    },
    computed: {
        checkListFiltered() {
            return this.$data.checkList.filter(f => !(f.name == "Sender email" && !this.$props.showEmailStep))
        }
    },
    mounted() {
        this.interval = setInterval(async () => {
            let response = await pdfService.getProgress(this.$props.URL);
            if (response.data.runtimeStatus == "Failed") {
                this.$emit("generateFailed", response.data.output);
            }
            else if (response.customStatus.CustomStatus == 8) {
                this.$emit("generateCompleted", response.data);
            }
            else if (response.customStatus.CustomStatus == 99) {
                this.$emit("generateFailed", response.data.output.ErrorMessage);
            }
            else if (this.step < response.customStatus.CustomStatus) {
                this.step = response.customStatus.CustomStatus;

                for (let i = 0; i < this.checkList.length; i++) {
                    if (this.step > i) {
                        this.checkList[i].completed = true;
                    }
                }
            }
        }, this.$props.intervalForStatus);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    }
}
</script>

<style scoped>
.icon-arrow {
    position: absolute;
    right: -15px;
    top: 20px;
}
</style>