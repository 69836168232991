<template>
  <div class="wrapper py-4">
    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="12" class="text-center">
          <img class="logo" alt="Silvan logo" src="./assets/silvan-logo.svg" />
          <h2 class="mt-3 mb-4 font-weight-bold">Skilte</h2>
        </b-col>
        <b-col cols="12" sm="10">
          <SelectTypeOfImport @selectedOption="selectedTypeOfImport" v-if="step == 1"
            :showExcelOption="showExcelOption" />
          <ExcelImport v-if="step == 2 && type == 'excel'" @reset="resetType" @goToProgress="goToProgress" />
          <InputDataImport :errorMessage="errorMessage" v-if="step == 2 && type == 'list'" @reset="resetType"
            @goToProgress="goToProgress" />
          <b-row v-if="step == 2 && errorMessage" class="justify-content-center mt-4">
            <b-col cols="12" lg="6">

            </b-col>
          </b-row>
          <ProgressReport v-if="step == 3" @generateCompleted="showResult" :URL="progressURL"
            :intervalForStatus="intervalForStatus" :showEmailStep="showEmailStatusStep"
            @generateFailed="showErrorMessage" />
          <ResultLinks @restart="restart" v-if="step == 4" :result="result" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SelectTypeOfImport from './components/SelectTypeOfImport.vue';
import ExcelImport from './components/ExcelImport.vue';
import InputDataImport from './components/InputDataImport.vue';
import ProgressReport from './components/ProgressReport.vue';
import ResultLinks from './components/ResultLinks.vue';

export default {
  name: 'App',
  components: {
    SelectTypeOfImport,
    ExcelImport,
    InputDataImport,
    ProgressReport,
    ResultLinks
  },
  data() {
    return {
      step: 1,
      type: "",
      progressURL: "",
      result: {},
      errorMessage: "",
      intervalForStatus: 0,
      showExcelOption: false,
      showEmailStatusStep: true
    }
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("password") && urlParams.get("password") == "c2lsdmFuLnNpZ24ucHJvZ3JhbS5leGNlbA==") {
      this.showExcelOption = true;
    }
    else {
      this.step = 2;
      this.type = "list";
    }
  },
  methods: {
    nextStep() {
      this.step++;
    },
    selectedTypeOfImport(val) {
      this.type = val;
      this.nextStep();
    },
    resetType() {
      this.type = "";
      this.step--;
      this.errorMessage = "";
    },
    goToProgress(progressObject) {
      this.progressURL = progressObject.statusUri;
      this.intervalForStatus = progressObject.milliseconds;
      this.showEmailStatusStep = progressObject.showEmailStep;
      this.step = 3;
    },
    showResult(result) {
      this.step = 4;
      this.result = result;
    },
    showErrorMessage(message) {
      this.step = 2;
      this.errorMessage = message;
    },
    restart() {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("password") && urlParams.get("password") == "c2lsdmFuLnNpZ24ucHJvZ3JhbS5leGNlbA==") {
        this.showExcelOption = true;
        this.step = 1;
        this.type = "";
      }
      else {
        this.step = 2;
        this.type = "list";
      }

      this.errorMessage = "";
      this.progressURL = "";
      this.result = {};
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
